import React from "react";
import { viewports } from "../../style-vars";

const CaseStudyModalStyles = () => (
  <style jsx global>{`
    .case-study {
      display: flex;
      flex-direction: column-reverse;
      flex-wrap: nowrap;
      color: #07204d !important;
      padding: 80px 10px;
      position: relative;
      overflow-x: hidden;
      overflow-y: scroll;
    }

    .case-study__bg--half-circle-filled {
      position: absolute;
      top: -24px;
      right: 10%;
    }

    .case-study__bg--half-circle {
      position: absolute;
      //right: -24px;
      //bottom: -24px;
      right: 0;
      bottom: 0;
    }

    .ant-modal-close-x {
      margin: 20px 20px 0 0 !important;
    }
    .ant-modal-close-x img {
      width: 25px;
    }

    .case-study__info {
      margin-top: 40px;
      z-index: 3;
    }

    .case-study__info h4.ant-typography {
      font-size: 18px;
      font-weight: bold;
      color: #07204d !important;
    }

    .case-study__info h1.ant-typography {
      font-size: 32px !important;
      font-weight: bold;
      color: #07204d !important;
      margin-top: 24px !important;
    }

    .case-study__business-type {
      font-size: 20px;
      font-weight: normal;
      margin-bottom: 24px;
    }

    .case-study__details-wrapper {
      list-style-type: none;
      padding: 0;
      margin-bottom: 24px;
    }
    .case-study__details {
      font-size: 14px;
      display: flex;
      flex-wrap: nowrap;
    }
    .case-study__details--key {
      flex: 0 1 30%;
    }
    .case-study__details--value {
      font-weight: bold;
    }

    .case-study__description {
      font-size: 16px;
      line-height: 26px;
    }

    .case-study__media {
      z-index: 1;
    }

    .case-study__media--video-wrapper {
      position: relative;
      width: 100%;
      padding-bottom: 56.25%;
      height: 0;
    }
    .case-study__media--video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 24px;
    }
    .case-study__media--image {
      border-radius: 24px;
    }

    @media ${viewports.lgOrBigger} {
      .case-study {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        padding: 135px 20px;
      }

      .ant-modal-close-x {
        margin: 50px 50px 0 0 !important;
      }
      .ant-modal-close-x img {
        width: auto;
      }

      .case-study__info {
        margin-top: 0;
      }

      .case-study__info h1.ant-typography {
        font-size: 42px !important;
      }

      .case-study__info,
      .case-study__media {
        flex: 0 1 45%;
        z-index: 1;
      }
    }

    @media ${viewports.xlOrBigger} {
      .case-study {
        padding: 100px;
      }
    }
  `}</style>
);

export default CaseStudyModalStyles;
