import React from "react";
import { string, func, number, object } from "prop-types";
import { Typography } from "antd";
import Image from "../../components/ImageQuerys/RotarySectionImagesV2";
import Heading from "../Base/HeadingBuilder";

const { Title } = Typography;

const RotaryCard = ({
  onClick,
  index,
  businessName,
  businessType,
  thumbnail,
  style,
  className,
}) => {
  const clickCallback = () => {
    onClick(index);
  };

  return (
    <div
      className={`rotary-card ${className}`}
      onClick={clickCallback}
      style={style}
    >
      <Image className="rotary-card__image" imageName={thumbnail} />
      <Heading
        level={3}
        injectionType={4}
        className="rotary-card__business-name"
      >
        {businessName}
      </Heading>
      <div className="rotary-card__business-type">{businessType}</div>
    </div>
  );
};

RotaryCard.propTypes = {
  onClick: func,
  index: number,
  businessName: string,
  businessType: string,
  thumbnail: string,
  style: object,
  className: string,
};

RotaryCard.defaultProps = {
  onClick: () => true,
  index: 0,
  businessName: "",
  businessType: "",
  thumbnail: "",
  style: {},
  className: "",
};

export default RotaryCard;
