import React from "react";
import { viewports } from "../../style-vars";

const RotarySectionStyles = () => (
  <style jsx global>{`
    .ant-modal-content {
      border-radius: 32px;
    }
    .ant-modal-body,
    .ant-modal-body .case-study {
      height: 100% !important;
    }

    .rotary__bg {
      position: absolute;
      top: -250px;
      left: -350px;
    }

    .rotary-card {
      max-width: 267px;
      width: 100%;
      height: auto;
      text-align: center;
      transition: 0.5s;
    }
    .rotary-card.visible {
      transition-duration: 0.8s;
    }
    .rotary-card.visible:hover {
      cursor: pointer;
      transform: translateY(0) scale(1.1);
    }
    .rotary-card__business-name {
      color: #090d12 !important;
      margin-top: 16px;
      margin-bottom: 10px;
      font-size: 20px;
    }
    .rotary-card__business-type {
      font-size: 14px;
      color: #97a0ac;
    }

    .rotary__cards--phone {
      margin-top: -40%;
    }
    .rotary__cards--phone li.slider-slide {
      display: flex !important;
      justify-content: center !important;
    }

    .rotary__cards--desktop {
      display: none;
    }
    .rotary__cards--desktop .rotary-card {
      margin: 0 23px;
    }

    @media ${viewports.smOnly} {
      .rotary__cards--phone {
        margin-top: -20%;
      }
    }

    @media ${viewports.mdOrBigger} {
      .rotary__cards--desktop {
        display: block;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -10%;
        margin-bottom: 10px;
      }

      .rotary__cards--phone {
        display: none;
      }

      .rotary__bg {
        position: absolute;
        top: -350px;
      }
    }

    @media ${viewports.lgOrBigger} {
      .rotary__bg {
        top: -250px;
        left: -250px;
      }
    }

    @media ${viewports.xlOrBigger} {
      .rotary__info {
        padding: 40px;
      }

      .rotary__info--cta {
        margin-top: 75px;
      }

      .rotary-card {
        max-width: 240px;
      }
    }
  `}</style>
);

export default RotarySectionStyles;
