import React, { useRef, useState } from "react";
import { object, bool } from "prop-types";
import { Modal } from "antd";
import Carousel from "nuka-carousel";
import { motion } from "framer-motion";
import { isMobileOnly } from "react-device-detect";
import RotarySectionStyles from "./styles";
import PrimaryCta from "../../components/Buttons/primary-cta";
import RotaryCard from "./card";
import Heading from "../Base/HeadingBuilder";
import ArrowLeft from "../../components/CarouselCustomControls/ArrowLeft";
import ArrowRight from "../../components/CarouselCustomControls/ArrowRight";
import CaseStudyModal from "./case-study-modal";
import background from "../../images/svg/circle.svg";
import closeIcon from "../../images/svg/close-icon.svg";
import useStartMotion from "../../hooks/use-start-motion";

const fadeInVariants = {
  hidden: { opacity: 0, y: 200 },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const imageVariants = {
  hidden: { opacity: 0, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      scale: {
        delay: 1,
        duration: 1,
      },
    },
  },
};

const RotarySection = ({ sectionData, animations }) => {
  const { title, data, className } = sectionData;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const backgroundRef = useRef(null);
  const paragraphRef = useRef(null);
  const { ref, inView, controls } = useStartMotion(animations);

  const showModal = (index) => {
    setModalContent(data[index]);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const sectionVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        opacity: {
          delay: 0.5,
          duration: 1,
        },
      },
    },
  };

  return (
    <motion.section
      className={`max-w-1440 relative pb-8 mt-20 lg:mt-36 lg:mx-8 overflow-hidden ${className}`}
      ref={ref}
      animate={controls}
      variants={sectionVariants}
      initial={isMobileOnly || !animations ? "visible" : "hidden"}
    >
      <motion.img
        ref={backgroundRef}
        className="rotary__bg"
        src={background}
        alt="section background"
        animate={controls}
        variants={imageVariants}
        initial={isMobileOnly || !animations ? "visible" : "hidden"}
      />
      <div
        className={`
        bg-black rounded-lg
        pt-20 px-4 pb-60
        md:pb-32
        lg:pb-48
        xl:pt-32 xl:px-32 xl:pb-72
        flex flex-col md:flex-row lg:gap-x-4
       `}
      >
        <motion.div
          className="flex-1/2"
          animate={controls}
          variants={fadeInVariants}
          initial={isMobileOnly || !animations ? "visible" : "hidden"}
          transition={{ delay: 0.7, duration: 0.8 }}
        >
          <Heading
            level={2}
            injectionType={1}
            withBlueSymbol
            className="text-white z-1 relative"
          >
            {title}
          </Heading>
        </motion.div>

        <motion.p
          className="self-center text-p z-1 flex-1/2 text-black-600 md:px-8 lg:px-16"
          ref={paragraphRef}
          animate={controls}
          variants={fadeInVariants}
          initial={isMobileOnly || !animations ? "visible" : "hidden"}
          transition={{ delay: 1.2, duration: 0.8 }}
        >
          {sectionData.subtext}
        </motion.p>
      </div>

      <div className="rotary__cards--phone">
        <Carousel
          slidesToShow={1.3}
          cellSpacing={20}
          cellAlign="center"
          dragging
          wrapAround
          initialSlideWidth={267}
          renderCenterLeftControls={({ previousSlide }) => (
            <ArrowLeft onClick={previousSlide} />
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <ArrowRight onClick={nextSlide} />
          )}
          renderBottomCenterControls={() => null}
        >
          {data.map((card, idx) => (
            <RotaryCard
              key={card.businessName}
              {...card}
              onClick={showModal}
              index={idx}
            />
          ))}
        </Carousel>
      </div>

      <div className="rotary__cards--desktop">
        {data.map((card, idx) => (
          <RotaryCard
            key={card.businessName}
            className={inView || isMobileOnly || !animations ? "visible" : ""}
            {...card}
            onClick={showModal}
            index={idx}
            style={{
              // transform: `translateY(${
              //   inView || isMobileOnly || !animations ? 0 : "200px"
              // })`,
              opacity: `${inView || isMobileOnly || !animations ? 1 : 0}`,
              // transitionDelay: `${1.5 + idx * 0.2}s`,
            }}
          />
        ))}
      </div>

      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        closeIcon={<img src={closeIcon} alt="close icon" />}
      >
        <CaseStudyModal {...modalContent} />
      </Modal>

      <RotarySectionStyles />
    </motion.section>
  );
};

RotarySection.propTypes = {
  sectionData: object.isRequired,
  animations: bool,
};

RotarySection.defaultProps = {
  animations: true,
};

export default RotarySection;
