import React from "react";
import { string, arrayOf, shape } from "prop-types";
import { Typography } from "antd";
import YouTube from "react-youtube";
import Image from "../../components/ImageQuerys/RotarySectionImagesV2";
import CaseStudyModalStyles from "./case-study-modal-styles";
import halfCircle from "../../images/svg/half-circle-big.svg";
import halfCircleFilled from "../../images/svg/half-circle-big-filled.svg";
import Heading from "../Base/HeadingBuilder";
import CtaPrimary from "../Base/CtaPrimary";

const { Title } = Typography;

const CaseStudyModal = (props) => {
  const {
    image,
    videoId,
    upperText,
    businessType,
    businessName,
    location,
    owner,
    website,
    paragraphs,
    stats,
    cta,
    modalTitle,
  } = props;

  return (
    <section className="case-study">
      <div className="case-study__info">
        <Title level={4}>{upperText}</Title>
        <Heading level={2} injectionType={2} className="my-6">
          {modalTitle || businessName}
        </Heading>
        <div className="case-study__business-type">{businessType}</div>
        {(!!location || !!owner || !!website) && (
          <ul className="case-study__details-wrapper">
            <li className="case-study__details">
              <span className="case-study__details--key">Location</span>
              <span className="case-study__details--value">{location}</span>
            </li>

            <li className="case-study__details">
              <span className="case-study__details--key">Owner/GM</span>
              <span className="case-study__details--value">{owner}</span>
            </li>

            <li className="case-study__details">
              <span className="case-study__details--key">Website</span>
              <span className="case-study__details--value">{website}</span>
            </li>
          </ul>
        )}

        {paragraphs.map((info, idx) => (
          <p key={idx.toString()} className="case-study__description">
            {info}
          </p>
        ))}

        {stats.length > 0 && (
          <div className="mt-6">
            {stats.map((stat) => (
              <div className="mb-4">
                <div className="text-p text-black font-bold">{stat.title}</div>
                <div className="text-p text-black-200 mt-1">
                  {stat.description}
                </div>
              </div>
            ))}
          </div>
        )}

        {cta && (
          <CtaPrimary className="mt-10" title={cta.title} target={cta.target} />
        )}
      </div>

      <div className="case-study__media">
        {videoId ? (
          <YouTube
            videoId={videoId}
            containerClassName="case-study__media--video-wrapper"
            className="case-study__media--video"
          />
        ) : (
          <Image imageName={image} className="case-study__media--image" />
        )}
      </div>

      <img
        className="case-study__bg--half-circle"
        src={halfCircle}
        alt="section background"
      />
      <img
        className="case-study__bg--half-circle-filled"
        src={halfCircleFilled}
        alt="section background"
      />

      <CaseStudyModalStyles />
    </section>
  );
};

CaseStudyModal.propTypes = {
  image: string,
  businessName: string,
  businessType: string,
  location: string,
  owner: string,
  website: string,
  paragraphs: arrayOf(string),
  stats: arrayOf(
    shape({
      title: string,
      description: string,
    })
  ),
  cta: shape({
    title: string,
    target: string,
  }),
  upperText: string,
  modalTitle: string,
};

CaseStudyModal.defaultProps = {
  image: "",
  businessName: "",
  businessType: "",
  location: "",
  owner: "",
  website: "",
  paragraphs: [],
  stats: [],
  cta: null,
  upperText: "Client stories",
  modalTitle: null,
};

export default CaseStudyModal;
